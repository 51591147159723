/* eslint-disable max-lines */
import { useQuery } from '@tanstack/react-query';
import {
	BASE_FRAME_LENS_OPTIONS,
	BASE_FRAME_LENS_VARIANTS,
	BASE_FRAME_RX_TYPE_VARIANTS,
	BaseVariantPriceDictionary,
	RX_TYPE,
} from '@utils/constants/base-skus';
import { FRAME_COLORS, LENSES_PACKAGES } from '@constants';
import { alphabetize, swap } from '@utils/arrays';
import { MoneyV2, SelectedOption } from '@ts/shopify-storefront-api';
import { parseBaseFrameVariant } from '@utils/shopify';
import { normalizeMetafields } from '@utils/normalizers';
import { NormalizedVariant } from '@ts/product';
import fetchStorefrontApi from '../fetch-storefront-api';
import { cleanGraphqlResponse } from '..';
import baseFrameVariantQuery from '../queries/base-frame-variant-query';
import baseFrameVariantsQuery from '../queries/all-base-frame-variants-query';

export type VariantState = {
	'handle': string;
	'Color': FRAME_COLORS;
	'Rx Type': BASE_FRAME_RX_TYPE_VARIANTS;
	'Lens': BASE_FRAME_LENS_VARIANTS;
	'country'?: string;
};

type VariantFetchResponse = {
	id: string;
	price: MoneyV2;
	selectedOptions: SelectedOption;
	title: `${FRAME_COLORS} / ${RX_TYPE} / ${BASE_FRAME_LENS_VARIANTS}`;
	metafields: NormalizedVariant['metafields'];
	compareAtPrice?: MoneyV2;
};

export type DynamicBaseFrameVariant = {
	state: VariantState;
	data: {
		variantBySelectedOptions: VariantFetchResponse;
	};
	isLoading: boolean;
};

const defaultOptions: VariantState = {
	'handle': 'the-casper-black',
	'Color': FRAME_COLORS.BLACK,
	'Rx Type': RX_TYPE.SINGLE_VISION,
	'Lens': 'Basic',
	'country': 'US',
};

export function createBaseFrameVariant(selectedLenses: Array<BASE_FRAME_LENS_OPTIONS>, shouldReturnBasic = false) {
	if (selectedLenses.length > 0) {
		let alphabeticLens = alphabetize(selectedLenses) as Array<BASE_FRAME_LENS_OPTIONS>;
		if (
			(selectedLenses.includes(BASE_FRAME_LENS_OPTIONS.BLUE_LIGHT) ||
				selectedLenses.includes(BASE_FRAME_LENS_OPTIONS.LIGHT_RESPONSIVE)) &&
			selectedLenses.includes(BASE_FRAME_LENS_OPTIONS.CR39)
		) {
			if (
				selectedLenses.includes(BASE_FRAME_LENS_OPTIONS.BLUE_LIGHT) &&
				selectedLenses.includes(BASE_FRAME_LENS_OPTIONS.LIGHT_RESPONSIVE)
			) {
				alphabeticLens.splice(selectedLenses.indexOf(BASE_FRAME_LENS_OPTIONS.BLUE_LIGHT), 1);
			}
			if (
				alphabeticLens.indexOf(BASE_FRAME_LENS_OPTIONS.CR39) < alphabeticLens.indexOf(BASE_FRAME_LENS_OPTIONS.BLUE_LIGHT)
			) {
				// Swap is done since in names of variants shopify, Blue Light or Light Responsive are positioned before Basic
				alphabeticLens = swap(
					alphabeticLens,
					alphabeticLens.indexOf(BASE_FRAME_LENS_OPTIONS.BLUE_LIGHT),
					alphabeticLens.indexOf(BASE_FRAME_LENS_OPTIONS.CR39)
				);
			}
			if (
				alphabeticLens.indexOf(BASE_FRAME_LENS_OPTIONS.CR39) <
				alphabeticLens.indexOf(BASE_FRAME_LENS_OPTIONS.LIGHT_RESPONSIVE)
			) {
				alphabeticLens = swap(
					alphabeticLens,
					alphabeticLens.indexOf(BASE_FRAME_LENS_OPTIONS.LIGHT_RESPONSIVE),
					alphabeticLens.indexOf(BASE_FRAME_LENS_OPTIONS.CR39)
				);
			}
		}
		return alphabeticLens.join(' + ') as BASE_FRAME_LENS_VARIANTS;
	}

	return shouldReturnBasic ? BASE_FRAME_LENS_OPTIONS.SUN_BLACK : BASE_FRAME_LENS_OPTIONS.NONE;
}

const useBaseFrameVariant = (state = defaultOptions): DynamicBaseFrameVariant => {
	const { data, isLoading } = useQuery(
		['variant-fetch', state, state.country],
		async () => {
			return await fetchBaseFrameVariant({
				...state,
				'Rx Type': state['Rx Type'] || defaultOptions['Rx Type'],
			});
		},
		{
			keepPreviousData: true,
		}
	);

	return { state, data, isLoading };
};

export const fetchBaseFrameVariant = async (options = defaultOptions) => {
	try {
		const { handle, country = 'US', ...argOptions } = options;
		// Normalize Lens options before fetching to prevent undefined BL + LR lens for metal temples
		const lensMapping = {
			'Blue Light + Light Responsive + Premium Plus': 'Light Responsive + Premium Plus',
			'Blue Light + Light Responsive': 'Light Responsive',
		};
		argOptions.Lens = lensMapping[argOptions.Lens] || argOptions.Lens;

		const fetchVariant = async variantOptions => {
			const { product } = await fetchStorefrontApi(baseFrameVariantQuery, {
				variables: {
					handle,
					selectedOptions: Object.entries(variantOptions).map(([name, value]) => ({ name, value })),
					country,
				},
			});
			const cleaned = cleanGraphqlResponse<{ variantBySelectedOptions: VariantFetchResponse }>(product);
			const {
				variantBySelectedOptions: { id, selectedOptions, title, price, compareAtPrice, ...rest },
			} = cleaned;
			return {
				variantBySelectedOptions: {
					id,
					price,
					selectedOptions,
					title,
					metafields: normalizeMetafields(rest),
					compareAtPrice,
				},
			};
		};

		let cleaned = await fetchVariant(argOptions);

		if (argOptions.Lens === 'None' && cleaned.variantBySelectedOptions === null) {
			cleaned = await fetchVariant({ ...argOptions, Lens: 'No Add-ons' });
		}

		return cleaned;
	} catch (error) {
		console.error(`Error fetching Base Frame Variant: ${error}`);
	}
};

export const useBaseFrameVariantPrices = (handle: string, country = 'US', initialData?: BaseVariantPriceDictionary) => {
	return useQuery(
		['base-frame-variant-prices', handle, country],
		async () => {
			return await fetchAllBaseFrameVariants(handle, country);
		},
		{
			initialData,
			//TODO: query doesn't seem to update when the frame color changes
		}
	);
};

export const fetchAllBaseFrameVariants = async (handle: string, country: string) => {
	try {
		const { product } = await fetchStorefrontApi(baseFrameVariantsQuery, {
			variables: {
				handle,
				country,
			},
		});
		const { variants } = cleanGraphqlResponse<{ variants: any }>(product);

		return variants.reduce((a, c) => {
			if (c.title.includes('None')) {
				const altTitle = c.title.replace('None', 'No Add-ons');
				a[altTitle] = c.price;
			}
			if (c.title.includes('No Add-ons')) {
				const altTitle = c.title.replace('No Add-ons', 'None');
				a[altTitle] = c.price;
			}
			a[c.title] = c.price;
			return a;
		}, {}) as BaseVariantPriceDictionary;
	} catch (error) {
		console.error(`Error fetching all Base Frame Variants with handle ${handle}: ${error}`);
	}
};

export const getVariantPriceDiff = ({
	lookup,
	current,
	lens,
	rxType,
	lensPackage,
	sunLensException,
	locale,
	currencyCode,
}: {
	lookup: BaseVariantPriceDictionary;
	current: DynamicBaseFrameVariant;
	selectedLenses?: Array<BASE_FRAME_LENS_OPTIONS>;
	lens?: BASE_FRAME_LENS_OPTIONS;
	rxType?: RX_TYPE;
	lensPackage?: LENSES_PACKAGES;
	sunLensException?: boolean;
	locale: string;
	currencyCode: string;
}) => {
	const info = parseBaseFrameVariant(current.data.variantBySelectedOptions.title);

	if (lens) {
		const isBasicPlan = lensPackage === LENSES_PACKAGES.BASIC;
		let currentVariantWithLens: keyof BaseVariantPriceDictionary = `${current.state.Color} / ${
			current.state['Rx Type']
		} / ${createBaseFrameVariant([...new Set([...info.lensType, lens])])}`;
		let currentVariantWithoutLens: keyof BaseVariantPriceDictionary = `${current.state.Color} / ${
			current.state['Rx Type']
		} / ${createBaseFrameVariant(
			info.lensType.filter(v => v !== lens),
			sunLensException
		)}`;

		if (isBasicPlan && currentVariantWithLens.split(' / ')[2] === 'Blue Light') {
			currentVariantWithLens = `${current.state.Color} / ${current.state['Rx Type']} / Blue Light + Basic`;
		}

		if (isBasicPlan && currentVariantWithoutLens.split(' / ')[2] === 'Blue Light + Basic') {
			currentVariantWithoutLens = `${current.state.Color} / ${current.state['Rx Type']} / Basic`;
		}

		if (currentVariantWithLens.split(' / ')[2] === 'Premium Plus + Basic') {
			currentVariantWithLens = `${current.state.Color} / ${current.state['Rx Type']} / Premium Plus`;
		}

		if (currentVariantWithLens.split(' / ')[2] === 'Premium Plus + Blue Light') {
			currentVariantWithLens = `${current.state.Color} / ${current.state['Rx Type']} / Blue Light + Premium Plus`;
		}

		if (currentVariantWithLens.split(' / ')[2] === 'Premium Plus + Blue Light + Basic') {
			currentVariantWithLens = `${current.state.Color} / ${current.state['Rx Type']} / Blue Light + Premium Plus`;
		}

		if (isBasicPlan && currentVariantWithoutLens.split(' / ')[2] === 'None') {
			currentVariantWithoutLens = `${current.state.Color} / ${current.state['Rx Type']} / Basic`;
		}

		if (isBasicPlan && currentVariantWithoutLens.split(' / ')[2] === 'Blue Light') {
			currentVariantWithoutLens = `${current.state.Color} / ${current.state['Rx Type']} / Blue Light + Basic`;
		}

		if (current.state['Rx Type'] === RX_TYPE.NON_RX && !isBasicPlan) {
			currentVariantWithLens = `${current.state.Color} / ${current.state['Rx Type']} / ${createBaseFrameVariant([lens])}`;
		}

		if (
			lens === BASE_FRAME_LENS_OPTIONS.LIGHT_RESPONSIVE &&
			(currentVariantWithLens.includes('Blue Light') || currentVariantWithoutLens.includes('Blue Light'))
		) {
			// This Removes Blue Light if the lens is Light Responsive. See if better way to solve this is possible.
			currentVariantWithLens = currentVariantWithLens
				.replace('Blue Light + ', '')
				.replace('Blue Light', 'None') as keyof BaseVariantPriceDictionary;
			currentVariantWithoutLens = currentVariantWithoutLens
				.replace('Blue Light + ', '')
				.replace('Blue Light', 'None') as keyof BaseVariantPriceDictionary;
		}

		try {
			return {
				with: {
					amount: parseInt(lookup[currentVariantWithLens].amount) - parseInt(lookup[currentVariantWithoutLens].amount),
					currencyCode: lookup[currentVariantWithLens].currencyCode,
					locale,
				},
				without: {
					amount:
						parseInt(current.data.variantBySelectedOptions.price.amount) -
						parseInt(lookup[currentVariantWithoutLens].amount),
					currencyCode: lookup[currentVariantWithoutLens].currencyCode,
					locale,
				},
			};
		} catch (error) {
			console.error(`Error getting pricing information for one or both of these variants: ${currentVariantWithLens}, ${currentVariantWithoutLens}
							${error}
				`);
			return {
				with: {
					amount: 0,
					currencyCode,
					locale,
				},
				without: {
					amount: 0,
					currencyCode,
					locale,
				},
			};
		}
	}

	if (lensPackage) {
		const currentVariantWithRx: keyof BaseVariantPriceDictionary = `${current.state.Color} / ${rxType ?? RX_TYPE.SINGLE_VISION} / None`;
		const currentVariantWithoutRx: keyof BaseVariantPriceDictionary = `${current.state.Color} / ${rxType ?? RX_TYPE.SINGLE_VISION} / Basic`;
		const premium =
			lookup[`${current.state.Color} / ${rxType ?? RX_TYPE.SINGLE_VISION} / Premium Plus`]?.amount -
			lookup[currentVariantWithoutRx]?.amount;
		const cr39Cost = lookup[currentVariantWithRx]?.amount - lookup[currentVariantWithoutRx]?.amount;
		const amount = {
			[LENSES_PACKAGES.BASIC]: 0,
			[LENSES_PACKAGES.STANDARD]: cr39Cost,
			[LENSES_PACKAGES.PREMIUM]: premium,
		};

		// In this case I assumed it is safe to go for the same amount for with or without
		return {
			with: {
				amount: amount[lensPackage],
				currencyCode: lookup[currentVariantWithRx]?.currencyCode,
				locale,
			},
			without: {
				amount: amount[lensPackage],
				currencyCode: lookup[currentVariantWithoutRx]?.currencyCode,
				locale,
			},
		};
	}

	if (rxType && info.lensColor && sunLensException) {
		const currentVariantWithRx: keyof BaseVariantPriceDictionary = `${current.state.Color} / ${rxType} / ${createBaseFrameVariant(info.lensType)}`;
		// TODO: what does "without" mean in this context? Non-Rx is safe to assume for now...
		const currentVariantWithoutRx: keyof BaseVariantPriceDictionary = `${current.state.Color} / ${RX_TYPE.NON_RX} / ${createBaseFrameVariant(info.lensType)}`;

		return {
			with: {
				amount: parseInt(lookup[currentVariantWithRx]?.amount) - parseInt(lookup[currentVariantWithoutRx]?.amount),
				currencyCode: lookup[currentVariantWithRx]?.currencyCode,
				locale,
			},
			without: {
				amount: parseInt(lookup[currentVariantWithoutRx]?.amount) - parseInt(lookup[currentVariantWithRx]?.amount),
				currencyCode: lookup[currentVariantWithoutRx]?.currencyCode,
				locale,
			},
		};
	}

	if (rxType) {
		const currentVariantWithRx: keyof BaseVariantPriceDictionary = `${current.state.Color} / ${rxType} / ${sunLensException ? 'None' : 'Basic'}`;
		// TODO: what does "without" mean in this context? Single Vision is safe to assume for now...
		const currentVariantWithoutRx: keyof BaseVariantPriceDictionary = `${current.state.Color} / ${RX_TYPE.SINGLE_VISION} / ${sunLensException ? 'None' : 'Basic'}`;

		return {
			with: {
				amount: parseInt(lookup[currentVariantWithRx]?.amount) - parseInt(lookup[currentVariantWithoutRx]?.amount),
				currencyCode: lookup[currentVariantWithRx]?.currencyCode,
				locale,
			},
			without: {
				amount: parseInt(lookup[currentVariantWithRx]?.amount) - parseInt(lookup[currentVariantWithoutRx]?.amount),
				currencyCode: lookup[currentVariantWithoutRx]?.currencyCode,
				locale,
			},
		};
	}
};

export const getBasePrice = (lookup: BaseVariantPriceDictionary, color?: string) => {
	return parseInt(lookup[`${color} / ${RX_TYPE.SINGLE_VISION} / ${BASE_FRAME_LENS_OPTIONS.CR39}`]?.amount) ?? 0;
};

export default useBaseFrameVariant;
