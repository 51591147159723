import { MutableRefObject, useEffect, useState, useRef } from 'react';
import { AnimatePresence, m } from 'framer-motion';
import { Root as PortalRoot } from '@radix-ui/react-portal';
import { useRouter } from 'next/router';
import { fadeLinear, slideFromPaddedBottom } from '@utils/motions';
import { Button, Flex, Heading, Lozenge, Paragraph, Tag } from '@components';
import variables from '@styles/export.module.scss';
import { formatCurrency } from '@utils/shopify';
import { BADGE_TYPES, LOCALE_CODES, LOCALE_DICT, MEDIUM_WIDTH, PRODUCT_TYPES } from '@constants';
import { NormalizedVariant } from '@ts/product';
import { useIsMobile } from '@utils/hooks';
import { capitalizeEachWord } from '@utils/strings';
import styles from './ProductViewCTA.module.scss';

type FloatingCTAProps = {
	ctaRef: MutableRefObject<HTMLButtonElement>;
	productType?: (typeof PRODUCT_TYPES)[keyof typeof PRODUCT_TYPES];
	selectedVariant?: NormalizedVariant;
	buttonProps: Parameters<typeof Button>[0] & {
		hasSubtotal?: boolean;
		isLoading?: boolean;
		availableForSale?: boolean;
		onClick?: (ref?: React.MutableRefObject<HTMLButtonElement | null>) => void;
	};
	name: string;
	subtotal?: number | string;
	minDigits?: number;
	maxDigits?: number;
	tag?: typeof BADGE_TYPES.TOP_RATED;
};

function FloatingCTA({
	ctaRef,
	selectedVariant,
	productType,
	buttonProps,
	name,
	subtotal,
	minDigits = 0,
	maxDigits = 0,
	tag,
}: FloatingCTAProps) {
	const [isButtonSticky, setIsButtonSticky] = useState(false);
	const isMobile = useIsMobile({ maxWidth: MEDIUM_WIDTH });
	const { locale } = useRouter();
	const currencyCode = selectedVariant?.price?.currencyCode ?? LOCALE_DICT[locale].currencyCode;
	const showCurr = locale === LOCALE_CODES.AU || locale === LOCALE_CODES.CA;
	const isBaseFrame = productType?.includes(PRODUCT_TYPES.BASE_FRAME);
	const compareAtPrice = selectedVariant?.compareAtPrice?.amount ?? null;
	const price = selectedVariant?.price?.amount || subtotal || null;
	const isPriceString = typeof price === 'string';
	const floatingCta = useRef<HTMLButtonElement>(null);

	useEffect(() => {
		const button = ctaRef.current;
		if (!button) return;
		const observerOptions = {
			root: null,
			rootMargin: '0px',
			threshold: 0,
		};

		const handleIntersection = entries => {
			entries.forEach(entry => {
				if (entry.isIntersecting) {
					setIsButtonSticky(false);
				} else {
					setIsButtonSticky(true);
				}
			});
		};
		const observer = new IntersectionObserver(handleIntersection, observerOptions);
		observer.observe(button);

		return () => {
			observer.unobserve(button);
		};
	}, []);

	return (
		<PortalRoot {...(typeof document !== 'undefined' && { container: document.getElementById('__next') })}>
			<AnimatePresence>
				{isButtonSticky && (
					<>
						<m.div className={styles.stickyButton} data-floating-cta={buttonProps.label} {...slideFromPaddedBottom}>
							<Flex column style={{ maxWidth: '40%' }} className={!!tag && styles['product-withTag']}>
								{tag && (
									<Lozenge extraClasses={styles.tag} key={`${tag.name}`} {...tag} shape='square'>
										{capitalizeEachWord(tag.displayText)}
									</Lozenge>
								)}
								<Heading className={styles['product-title']} tag='h6'>
									{name}
								</Heading>
								<Flex gap={2}>
									<Paragraph>
										{!compareAtPrice && isBaseFrame && (
											<span style={{ color: variables.gray4 }}>{`Starting at `}</span>
										)}
										<span
											style={{
												color: compareAtPrice ? variables.red1 : variables.gray4,
												fontSize: compareAtPrice && '1.2rem',
											}}
										>
											{isPriceString
												? price
												: formatCurrency(
														{ amount: price, locale, currencyCode, minDigits, maxDigits },
														showCurr
													)}
										</span>
									</Paragraph>
									{compareAtPrice && (
										<Paragraph
											style={{
												color: variables.gray3,
												textDecoration: 'line-through',
												fontSize: '0.8rem',
												alignContent: 'space-evenly',
											}}
										>
											{`${formatCurrency({ amount: compareAtPrice, locale, currencyCode }, showCurr)}`}
										</Paragraph>
									)}
								</Flex>
							</Flex>
							<Button
								extraClasses={styles['cta']}
								size={isMobile ? 'medium' : 'large'}
								color='green'
								onClick={() => buttonProps.onClick(floatingCta)}
								withChevron={buttonProps.withChevron}
								chevronDirection='right'
								withPrice={buttonProps?.hasSubtotal}
								price={buttonProps.price}
								label={buttonProps.label}
								disabled={!buttonProps.availableForSale}
								currencyCode={currencyCode}
								ref={floatingCta}
								{...buttonProps.dataTags}
							/>
						</m.div>
						<m.div className={styles.stickyGradient} {...fadeLinear} />
					</>
				)}
			</AnimatePresence>
		</PortalRoot>
	);
}

export default FloatingCTA;
