import { useMemo } from 'react';
import { useFeatureIsOn } from '@growthbook/growthbook-react';
import { useCartContext } from '@context';
import { calculateDiscount } from '@utils/discount';
import { DISCOUNT_RATES, PRODUCT_TYPES, useIsDiscountEligible } from '..';

interface UseDiscountedPriceProps {
	price: number;
	discountRate?: number;
	compareAtPrice?: number;
	type?: string;
	mandatoryDiscount?: boolean;
}

const useDiscountedPrice = ({
	price,
	discountRate = DISCOUNT_RATES.PERCENT_15,
	compareAtPrice,
	type,
	mandatoryDiscount = false,
}: UseDiscountedPriceProps) => {
	const { applySubscriptionDiscount } = useCartContext();
	const isV2Subscription = useFeatureIsOn('is-v2-subscription-test');
	const isDiscountEligible = useIsDiscountEligible({
		compareAtPrice,
		type,
		mandatoryDiscount,
	});

	const { discountedPrice, newCompareAtPrice } = useMemo(() => {
		if (!isDiscountEligible) {
			return { discountedPrice: price, newCompareAtPrice: compareAtPrice };
		}
		return { discountedPrice: calculateDiscount(discountRate, price), newCompareAtPrice: price };
	}, [price, applySubscriptionDiscount, discountRate, isV2Subscription, compareAtPrice, isDiscountEligible]);
	return [discountedPrice, newCompareAtPrice];
};

export default useDiscountedPrice;
