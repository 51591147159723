import { Fragment, useCallback } from 'react';
import cn from 'classnames';
import variables from '@styles/export.module.scss';
import { BUILD_FLOW_STEPS } from '@constants';
import { useBFContext } from '@context';
import { Checkmark, Flex } from '@components';
import styles from './StepTracker.module.scss';
import { useFeatureIsOn } from '@growthbook/growthbook-react';

type Tracker = {
	isComplete: boolean;
	isCurrent: boolean;
	copy: string;
	step?: BUILD_FLOW_STEPS;
	isSkip?: boolean;
};

const generateSteps = (currentStep, isSubscriptionActivated: boolean, skipSubscription: boolean): Tracker[] => {
	return [
		{
			copy: 'Frame Color',
			isComplete: currentStep > BUILD_FLOW_STEPS.COLOR,
			isCurrent: currentStep === BUILD_FLOW_STEPS.COLOR,
		},
		{
			copy: 'Lens Type',
			isComplete: currentStep > BUILD_FLOW_STEPS.CUSTOMIZE,
			isCurrent: currentStep === BUILD_FLOW_STEPS.LENS || currentStep === BUILD_FLOW_STEPS.CUSTOMIZE,
		},
		isSubscriptionActivated && {
			copy: 'Subscription',
			isComplete: currentStep > BUILD_FLOW_STEPS.SUBSCRIPTION,
			isCurrent: currentStep === BUILD_FLOW_STEPS.SUBSCRIPTION,
			isSkip: skipSubscription,
		},
		{
			copy: 'Top Frames',
			isComplete: currentStep > BUILD_FLOW_STEPS.TOP_FRAMES,
			isCurrent: currentStep === BUILD_FLOW_STEPS.TOP_FRAMES,
		},
	].filter(Boolean);
};

const StepTracker = () => {
	const { setStep, step, isSubscriptionActivated, skipSubscription } = useBFContext();
	const isV2Subscription = useFeatureIsOn('is-v2-subscription-test');
	const steps: Tracker[] = generateSteps(step, isSubscriptionActivated && !isV2Subscription, skipSubscription);

	function handleGoTo(goToStep) {
		switch (goToStep) {
			case 'GO_TO_COLORS':
				setStep(BUILD_FLOW_STEPS.COLOR);
				break;
			case 'GO_TO_PRESCRIPTIONS':
				setStep(BUILD_FLOW_STEPS.LENS);
				break;
			case 'GO_TO_COLLECTIONS':
				setStep(BUILD_FLOW_STEPS.TOP_FRAMES);
				break;
		}
	}

	const renderTrackerItem = useCallback(
		({ isComplete, isCurrent, copy, isSkip }: Tracker, i: number) => {
			const styleState = { [styles['complete']]: isComplete, [styles['current']]: isCurrent, [styles['skip']]: isSkip };
			const stroke = isComplete ? variables.blue1 : variables.gray3;
			const hasDash = i < steps.length - 1;

			let goToStep = 'GO_TO_COLORS';
			if (copy === 'Lens Type') goToStep = 'GO_TO_PRESCRIPTIONS';
			if (copy === 'Top Frames') goToStep = 'GO_TO_COLLECTIONS';

			return (
				<li
					data-test-step={isCurrent ? copy : ''}
					className={styles.step}
					title={isComplete ? `Go to ${copy} Step` : undefined}
				>
					<Flex column align='center'>
						<div className={cn(styles.circle, styleState)}>
							{isComplete && <Checkmark thick />}
							{!isComplete && <span className={isCurrent || isComplete ? styles.complete : ''}>{i + 1}</span>}
						</div>
						<div className={cn(styles.copy, styleState)} onClick={() => isComplete && handleGoTo(goToStep)}>
							{copy}
						</div>
					</Flex>
					{hasDash && (
						<div
							className={styles.line}
							style={{
								backgroundColor: stroke,
							}}
						/>
					)}
				</li>
			);
		},
		[steps.length]
	);

	return (
		<ul className={styles.container} data-step-tracker>
			{steps.map((mark, index) => (
				<Fragment key={mark.copy}>{renderTrackerItem(mark, index)}</Fragment>
			))}
		</ul>
	);
};

export default StepTracker;
